import * as React from "react"
import Sidebar from '../components/sidebar';
import { Helmet } from "react-helmet"
// markup
const NotFoundPage = () => {
  return (
    <Sidebar>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Oh no.</title>
      </Helmet>
      <article className="prose dark:prose-light max-w-none">
        <h1>404 - Not Found</h1>
        <p>Whatever you're trying to look for, it's not here. It's either moved, or it never existed in the first place.</p>
      </article>
    </Sidebar>   
  )
}

export default NotFoundPage
